.image-button {
  width: 18px;
  height: 18px;
  background-image: url('../../assets/images/arrow-left-circle.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  cursor: pointer;
  background-color: white;
}

.modal {
  display: none;
  position: fixed;
  z-index: 7;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #333;
  color: #eee;
}
.modal-content {
  position: relative;
  background-color: #333;
  color: #eee;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
}
.modal .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
}
.drawflow .drawflow-node .input {
  left: -13px;
  top: 2px;
  background: #ff0;
}
.drawflow svg {
  z-index: 0;
  position: absolute;
  overflow: visible !important;
}
.drawflow .connection {
  position: absolute;
  transform: translate(9999px, 9999px);
}
.drawflow .connection .main-path {
  fill: none;
  stroke-width: 3px;
  stroke: black;
  transform: translate(-9999px, -9999px);
}
.drawflow .connection .main-path:hover {
  stroke: black;
  cursor: pointer;
}
.drawflow .connection .main-path.selected {
  stroke: #43b993;
}
.drawflow .connection .point {
  cursor: move;
  stroke: #000;
  stroke-width: 2;
  fill: #fff;
  transform: translate(-9999px, -9999px);
}
.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #1266ab;
}
.drawflow .main-path {
  fill: none;
  stroke-width: 3px;
  stroke: black;
}
.drawflow .selectbox {
  z-index: 3;
  position: absolute;
  transform: translate(9999px, 9999px);
}
.drawflow .selectbox rect {
  fill: #00f;
  opacity: 0.5;
  stroke: #ff0;
  stroke-width: 5;
  stroke-opacity: 0.5;
  transform: translate(-9999px, -9999px);
}
.drawflow-delete {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: #000;
  color: #fff;
  z-index: 4;
  border: 2px solid #fff;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}
.text-desc {
  width: 257px;
  font-size: 11px;
  color: #868686;
}
.text-desc1 {
  display: flex;
  font-size: 11px;
  color: #868686;
  justify-content: space-around;
  position: relative;
  bottom: 19px;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.bg-red {
  border: 1px #fdacae solid;
  background-color: #fee5e6;
  height: 50px;
}
.conf-title {
  padding: 12px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: #868686;
    font-size: 12px;
    margin: 0;
  }

  .close-panel {
    color: #868686;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: 1px solid #c5c4c4;
  }

  .title-1 {
    flex: 80%;
    display: flex;
    align-items: center;
  }

  .title-2 {
    flex: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .config-form {
    display: flex;
    flex-direction: column;

    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }

  .container-tabs {
    padding-bottom: 5px;
  }
}
