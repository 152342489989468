:root {
  --border-color: #cacaca;
  --background-color: #fff;
  --background-box-title: #f7f7f7;
}

.draw-section-left {
  width: 320px;
}

.container-products {
  height: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid #c5c4c4;
  background: #fff;
}

.container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 1px solid#E8E8E8;
  .title {
    color: #868686;
    font-size: 12px;
    margin: 0;
  }
}

.container-search {
  padding: 19px 19px 0 19px;
}

.search-box {
  width: 100%;
  .search {
    display: flex;
    align-items: center;
    background: #e7e7e7;
    height: 40px;
    border-radius: 20px;
    input {
      background: transparent;
      border: 0;
      padding: 8px 8px 8px 15px;
      font-size: 12px;
      width: 88%;
    }
    .mat-icon {
      font-size: 18px;
      color: #868686;
      height: 18px;
      width: 18px;
    }
  }
}

.container-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 19px 0 19px;
}

.image-button-warn {
  width: 22px;
  height: 22px;
  background-image: url('../../assets/images/icon-warn.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  cursor: pointer;
  background-color: white;
}

.asset-list-block {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.collapsed {
  grid-template-columns: 60px 3fr 0fr;
  transition: width 2s;

  .container-products {
    width: 60px;
    transition: width 2s;
    height: 100vh;
  }

  .draw-section-left {
    width: 10%;
    border-right: 0;
    display: contents;
  }

  .styleWidth {
    width: 100%;
  }

  .container-tabs,
  .title,
  .asset-list-block,
  .container-data,
  .container-search {
    display: none;
  }

  .image-button {
    width: 20px;
    height: 14px;
    background-image: url('../../assets/images/menu-icon.png');
    margin: 10px 0;
  }
}

.myDiagramDiv1 {
  height: 100%;
  background-color: rgba(248, 248, 248, 1);
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20"%3E%3Ccircle cx="10" cy="10" r="3" fill="rgba(64, 64, 64, 0.05)" /%3E%3C/svg%3E');
}

.conditionWidth {
  width: 56%;
  background: #efeeee;
  height: 819px;
}

.quantilyErrorLabel {
  width: 140px;
}

.wrapper {
  width: 100%;
  height: calc(100vh - 134px);
  display: flex;
}

.draw-section {
  width: 80%;
  &.conditionWidth {
    width: 56%;
  }
}

.brd-clr {
  color: rgba(134, 134, 134, 1);
}

.label-12 {
  font-size: 12px;
}

.quantity {
  width: 80px !important;
  background: rgba(49, 160, 255, 0.2);
  height: 40px !important;
  font-size: 12px;
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .mat-mdc-form-field-infix {
    min-height: 40px;
  }
}

.drawflow > .drawflow-delete {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete {
  right: -15px;
  top: -15px;
}

.closePointer {
  cursor: pointer;
}

.container-conf-drawflow {
  width: 25%;
  height: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid #c5c4c4;
  z-index: 999 !important;
  background: #fff;

  .conf-body {
    padding: 20px 22px;

    .description {
      font-size: 12px;
      color: #868686;
      margin-bottom: 30px;
    }
  }
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  display: flex !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.drawflow .drawflow-node .output {
  top: 10px !important;
  right: 0px !important;
  position: relative !important;
  top: 2px !important;
}

.drawflow .drawflow-node .outputs {
  height: 0px !important;
  gap: 10px;
}

.drawflow .drawflow-node .inputs {
  height: 0px !important;
  gap: 10px;
}

.drawflow .drawflow-node .input {
  left: 0px !important;
  height: 0px !important;
  position: relative !important;
  top: 2px !important;
  background: #ff0;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  position: relative;
  width: 20px;
  height: 20px;
  height: 15px;
  width: 15px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #000;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 5px;
}

.drawflow .drawflow-node .input {
  left: -13px;
  top: 2px;
  background: #ff0;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  position: relative;
  width: 14px !important;
  height: 14px !important;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #000 !important;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 5px;
}

.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
  background: var(--border-color);
}
