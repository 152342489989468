.drag-drawflow {
  display: flex;
  align-items: center;
  min-height: 56px;
  gap: 15px;
  cursor: move;
  user-select: none;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 10px 15px;

  .asset-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .asset-info {
    flex: 1;
    .asset-title {
      font-size: 12px;
      margin: 0;
    }
    .asset-desc {
      color: #868686;
      font-size: 10px;
      margin: 0;
    }
  }
}

.drawflow-btn {
  padding: 0 20px;
}

.tab-selected {
  color: #41a3f3;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #fff;
  border: 3px solid rgba(255, 255, 255, 0);
  border-bottom-color: #41a3f3;
  padding: 10px 20px;
  margin: 0 5px;
}

.tab {
  color: #868686;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0);
  border-bottom-color: rgba(255, 255, 255, 0);
  padding: 13px 20px;
  margin: 0 5px;
}

#drawflow {
  position: relative;
  //width: calc(100vw - 342px);
  height: calc(100% - 50px);
  top: 40px;
}
@media only screen and (max-width: 768px) {
  .col {
    width: 50px;
  }
  .col .drag-drawflow span {
    display: none;
  }
  #drawflow {
    width: calc(100vw - 60vh);
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 130px;
  }
}
.drawflow .drawflow-node {
  display: block !important;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: white;
  border: 1px solid #41a3f3;
  -webkit-box-shadow: 0 2px 15px 2px var(--border-color);
  box-shadow: 0 2px 15px 2px var(--border-color);
  padding: 0px;
  width: 200px;
  color: #41a3f3;
  border-radius: 6px;
}
.drawflow .drawflow-node.selected {
  border: 1px solid black;
  -webkit-box-shadow: 0 2px 20px 2px var(--border-color);
  box-shadow: 0 2px 20px 2px var(--border-color);
  color: rgb(48, 46, 46);
  background: rgba(49, 160, 255, 0.2);
}
.drawflow .drawflow-node.selected .title-box {
  color: rgb(48, 46, 46);
}
.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  height: 15px;
  width: 15px;
  border: 2px solid var(--border-color);
}
.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
  background: var(--border-color);
}
.drawflow .drawflow-node .output {
  right: 10px;
}
.drawflow-node .title-box {
  display: flex;
  height: 50px;
  line-height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 2px 2px 0px 0px;
  font-size: 12px;
}
.drawflow-node .title-box2 {
  display: flex;
  height: 50px;
  line-height: 35px;
  justify-content: center;
  border-radius: 2px 2px 0px 0px;
}

.drawflow .title-box svg {
  position: initial;
}
.drawflow-node .box {
  padding: 10px 20px 20px 20px;
  font-size: 14px;
  color: var(--color);
}
.drawflow-node .box p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.drawflow .drawflow-node.selected .box {
  border-radius: 5px;
}
.drawflow-node.welcome {
  width: 250px;
}
.drawflow-node.slack .title-box {
  border-radius: 4px;
}
.drawflow-node input,
.drawflow-node select,
.drawflow-node textarea {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  width: 158px;
  color: #555555;
}
.drawflow-node textarea {
  height: 100px;
}
.drawflow-node.personalized .input {
  background: yellow;
}
.drawflow .connection .point {
  stroke: var(--border-color);
  stroke-width: 2;
  fill: var(--color);
  transform: translate(-9999px, -9999px);
}
.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: var(--border-color);
}
.closePointer {
  cursor: pointer;
}
.drawflow,
.drawflow .parent-node {
  position: relative;
}
.parent-drawflow {
  display: flex;
  overflow: hidden;
  touch-action: none;
  outline: 0;
}
.drawflow {
  width: 100%;
  height: 100%;
  user-select: none;
}
.drawflow .drawflow-node:hover {
  cursor: move;
}
.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  width: 0;
}
.drawflow .drawflow-node .drawflow_content_node {
  width: 100%;
  display: block;
  height: 50px;
}
.drawflow .drawflow-node .input {
  left: -13px;
  top: 2px;
  background: #ff0;
}
