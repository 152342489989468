.fieldClass {
  width: 100%;
}

::ng-deep {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: #c7c7c7;
  }
}
