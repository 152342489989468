/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@use 'assets/styles/index';

@use 'assets/styles/palette';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

@import './packages/ui-components/src/lib/draw-flow/draw-flow.component.scss';

@import './packages/ui-components/src/lib/draw-flow/draw-flow-node.component.scss';

@import './packages/ui-components/src/lib/draw-flow/draw-flow-node2.component.scss';

@import './packages/ui-components/src/lib/draw-flow/draw-flow-node3.component.scss';

//@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css');

//@import 'bootstrap/scss/bootstrap.scss';

@import '~video.js/dist/video-js.css';

@import '~grapesjs/dist/css/grapes.min.css';

@include mat.core();

$genera-primary: mat.define-palette(palette.$blue-palette);
$genera-accent: mat.define-palette(palette.$green-palette);
$genera-warn: mat.define-palette(mat.$red-palette);

$genera-theme: mat.define-light-theme(
  (
    color: (
      primary: $genera-primary,
      accent: $genera-accent,
      warn: $genera-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: '"Inter", sans-serif',
      ),
  )
);

@include mat.all-component-themes($genera-theme);

body {
  font-family: 'Inter', sans-serif !important;
  background: #f6f8fc !important;
}

.search-box,
.brand_filter {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
